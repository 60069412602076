import React, { useState } from 'react';
import { Roles } from 'constants/index';
import Icon from '@onedesign/icon';
import { useFooterStatus } from 'providers/FooterStatusProvider';
import styled from 'styled-components';
import { ChatRoom } from './ChatRoom';

const ChatRoomButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ isChatRoomOpen }) => (isChatRoomOpen ? '#5fa1a5' : '#75bec2')};
  width: 100%;
  height: 56px;
  border-radius: 100px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0px 8px 16px 0px rgba(145, 158, 171, 0.16);
  gap: 4px;
  &:hover {
    background-color: #5fa1a5;
  }
`;

const ChatRoomText = styled.p`
  color: #fff;
  text-align: center;
  margin: 0;
  font-size: 18px;
  font-weight: 700;
`;

const NotificationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${(props) => (props.isHighCount ? '10px' : '100%')};
  background: #ff7257;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  width: ${(props) => (props.isHighCount ? '28px' : '20px')};
  padding: ${(props) => (props.isHighCount ? '0px 6.5px 1px 6.5px' : '0')};
`;

const ChatRoomContainer = styled.div`
  display: ${(props) => (props.isHidden ? 'none' : 'block')};
  position: absolute;
  right: 9px;
  bottom: ${({ isFooterOpen, isObserver }) => (isObserver ? '85px' : isFooterOpen ? '147px' : '129px')};
  z-index: 3;
  box-shadow: 0px -1px 16px 0px rgba(145, 158, 171, 0.16);
`;

const ArrowIcon = styled.div`
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #fff;
  cursor: pointer;
  position: absolute;
  bottom: -8px;
  right: 26px;
`;

export default function ChatRoomButton({ role }) {
  const { isFooterOpen } = useFooterStatus();
  const [isChatRoomOpen, setChatRoomOpen] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(0);

  const toggleChatRoom = () => {
    setChatRoomOpen(!isChatRoomOpen);
    if (isChatRoomOpen === false) {
      setUnreadMessages(0);
    }
  };

  return (
    <>
      <ChatRoomContainer isHidden={!isChatRoomOpen} isFooterOpen={isFooterOpen} isObserver={role === Roles.Observer}>
        <ArrowIcon onClick={toggleChatRoom} />
        <ChatRoom
          toggleChatRoom={toggleChatRoom}
          setUnreadMessages={setUnreadMessages}
          isChatRoomOpen={isChatRoomOpen}
          isFooterOpen={isFooterOpen}
        />
      </ChatRoomContainer>
      <ChatRoomButtonWrapper isFooterOpen={isFooterOpen} isObserver={role === Roles.Observer}>
        <ButtonContainer onClick={toggleChatRoom} isChatRoomOpen={isChatRoomOpen}>
          <Icon name={'CommentsSolid'} size='xl' color='#FFFFFF' />
          <ChatRoomText>訊息</ChatRoomText>
          {unreadMessages > 0 && (
            <NotificationWrapper isHighCount={unreadMessages > 9}>
              {unreadMessages > 9 ? '9+' : unreadMessages}
            </NotificationWrapper>
          )}
        </ButtonContainer>
      </ChatRoomButtonWrapper>
    </>
  );
}
