import { createMachine, assign, send } from 'xstate';
export const States = {
  Idle: 'Idle',
  Joining: 'Joining',
  Joined: 'Joined',
  Failure: 'Failure',
  Whiteboard: 'Whiteboard',
  Whiteboarding: 'Whiteboarding',
  RaiseHand: 'RaiseHand',
  RaisingHand: 'RaisingHand',
  DisableChat: 'DisableChat',
  BreakoutMeetingInvite: 'BreakoutMeetingInvite',
  Inviting: 'Inviting',
  BreakoutMeetingLeave: 'BreakoutMeetingLeave',
  Leaving: 'Leaving',
  BreakoutMeeting: 'BreakoutMeeting',
  BreakoutMeetingGrouping: 'BreakoutMeetingGrouping',
  Toolbox: 'Toolbox',
  BroadcastMessage: 'BroadcastMessage',
  Open: 'Open',

  Quiz: 'Quiz',
  Setting: 'Setting',
  Starting: 'Starting',
  End: 'End',
  Reviewing: 'Reviewing',

  TileState: 'TileState',
  Active: 'Active',
  Paused: 'Paused',

  RewardAnimationState: 'RewardAnimationState',

  RecordVideo: 'RecordVideo',

  ClassroomPerformance: ' ClassroomPerformance'
};

export const Roles = {
  Teacher: 'teacher',
  Student: 'student',
  Advisor: 'advisor',
  Observer: 'observer'
}

const RECORD_VIDEO_STATE = {
  Idle: 'Idle',
  On: 'On',
  Off: 'Off'
};

const RECORD_VIDEO_EVENT = {
  turnOn: 'turnOn',
  turnOff: 'turnOff'
};

export const ActionTypes = {
  Join: 'Join',
  OnStage: 'OnStage',
  Unstage: 'Unstage',
  DisableChat: 'DisableChat',
  EnableChat: 'EnableChat',
  OnStageFromAdvisor: 'OnStageFromAdvisor',
  UnstageFromAdvisor: 'UnstageFromAdvisor',
  UpdateStaged: 'UpdateStaged',
  UpdateAppStaged: 'UpdateAppStaged',
  ClearStage: 'ClearStage',
  SetRoleState: 'SetRoleState',
  RaiseHand: 'RaiseHand',
  LowerHand: 'LowerHand',
  LowerHandFromTeacher: 'LowerHandFromTeacher',
  LowerHandFromAdvisor: 'LowerHandFromAdvisor',
  LowerAllAttendeesHand: 'LowerAllAttendeesHand',
  LowerAllAttendeesHandFromAdvisor: 'LowerAllAttendeesHandFromAdvisor',
  MuteAttendee: 'MuteAttendee',
  UnMuteAttendee: 'UnMuteAttendee',
  MuteAttendeeFromAdvisor: 'MuteAttendeeFromAdvisor',
  UnMuteAttendeeFromAdvisor: 'UnMuteAttendeeFromAdvisor',
  MuteAllAttendees: 'MuteAllAttendees',
  UnMuteAllAttendees: 'UnMuteAllAttendees',
  StartWhiteboard: 'StartWhiteboard',
  StartAllWhiteboard: 'StartAllWhiteboard',
  EndWhiteboard: 'EndWhiteboard',
  SendReward: 'SendReward',
  SendRewardFromAdvisor: 'SendRewardFromAdvisor',
  PauseReward: 'PauseReward',
  PauseRewardFromAdvisor: 'PauseRewardFromAdvisor',
  UpdateReward: 'UpdateReward',
  UpdateRewardFromAdvisor: 'UpdateRewardFromAdvisor',
  OpenVideoAttendee: 'OpenVideoAttendee',
  CloseVideoAttendee: 'CloseVideoAttendee',
  OpenVideoAttendeeFromAdvisor: 'OpenVideoAttendeeFromAdvisor',
  CloseVideoAttendeeFromAdvisor: 'CloseVideoAttendeeFromAdvisor',
  NoFocus: 'NoFocus',
  VideoIsMirroring: 'VideoIsMirroring',
  UpdateVideoList: 'UpdateVideoList',
  UpdateOrderRosters: 'UpdateOrderRosters',
  AddOrderRosters: 'AddOrderRosters',
  RemoveOrderRosters: 'RemoveOrderRosters',

  QuizSet: 'QuizSet',
  QuizStart: 'QuizStart',
  QuizAnswer: 'QuizAnswer',
  QuizEnd: 'QuizEnd',
  QuizReview: 'QuizReview',
  QuizClose: 'QuizClose',

  ClearVideoBackgroundEffect: 'ClearVideoBackgroundEffect',
  VideoBlurBackgroundEffect: 'VideoBlurBackgroundEffect',
  VideoBackgroundImageEffect: 'VideoBackgroundImageEffect',

  SetWorldWall: 'SetWorldWall',
  EnableToolbox: 'EnableToolbox',
  DisableToolbox: 'DisableToolbox',
  UpdateToolbox: 'UpdateToolbox',
  AddToolboxAuthAttendeeIds: 'AddToolboxAuthAttendeeIds',
  RemoveToolboxAuthAttendeeIds: 'RemoveToolboxAuthAttendeeIds',
  AdvisorEnableToolbox: 'AdvisorEnableToolbox',
  AdvisorDisableToolbox: 'AdvisorDisableToolbox',

  BreakoutMeetingInvite: 'BreakoutMeetingInvite',
  BreakoutMeetingLeave: 'BreakoutMeetingLeave',
  BreakoutMeetingEnd: 'BreakoutMeetingEnd',
  CallTeacherGroups: 'CallTeacherGroups',
  RemoveCallTeacherGroups: 'RemoveCallTeacherGroups',
  RemoveAllCallTeacherGroups: 'RemoveAllCallTeacherGroups',
  EnterBreakoutRoom: 'EnterBreakoutRoom',
  LeaveBreakoutRoom: 'LeaveBreakoutRoom',
  BroadcastMessageToAll: 'BroadcastMessageToAll',
  CloseBroadcastModal: 'CloseBroadcastModal',
  OnObject: 'OnObject',
  UnObject: 'UnObject',

  AssignMultiUserRewards: 'AssignMultiUserRewards',

  ActiveTile: 'ActiveTile',
  PausedTile: 'PausedTile',

  GetSessionInfoSuccess: 'GetSessionInfoSuccess',
  GetSessionInfoFailure: 'GetSessionInfoFailure',
  GetGroupInfo: 'GetGroupInfo',

  TeacherStartRecord: 'TeacherStartRecord',
  TeacherStopRecord: 'TeacherStopRecord',
  AdvisorStartRecord: 'AdvisorStartRecord',
  AdvisorStopRecord: 'AdvisorStopRecord',
  ObserverStopRecord: 'ObserverStopRecord',

  OpenClassroomPerformance: 'OpenClassroomPerformance',
  CloseClassroomPerformance: 'CloseClassroomPerformance',
};

export const BreakoutRoomActionTypes = {
  startActivity: ActionTypes.BreakoutMeetingInvite,
  stopActivity: ActionTypes.BreakoutMeetingLeave,
  callTeacher: ActionTypes.CallTeacherGroups,
  enterBreakoutRoom: ActionTypes.EnterBreakoutRoom,
  leaveBreakoutRoom: ActionTypes.LeaveBreakoutRoom,
  broadcastMessageToAll: ActionTypes.BroadcastMessageToAll,
}

const callTeacherGroupsInit = () => {
  const breakoutRoomStorage = window.localStorage;
  const callTeacherGroupsInitData = JSON.parse(breakoutRoomStorage.getItem('callTeacherData')) || [];
  return callTeacherGroupsInitData
}

export const machine = createMachine({
  id: 'meetingMachine',
  context: {
    joinInfo: {},
    courseType: '',
    attendeeId: '',
    userId: '',
    userName: '',
    roomId: '',
    role: '',
    error: {},
    orderRosters: [],
    videoList: [],
    stagedAttendeeIds: [],
    raisedHandAttendeeIds: [],
    chatDisabledAttendeeIds: [],
    whiteboardingAttendeeIds: [],
    videoIsMirroring: true,
    rewards: {
      animate: []
    },
    quiz: {
      optionCount: 0,
      attendeeAnswers: {}
    },
    worldWall: {
      switch: false,
      url: ''
    },
    objectedAttendeeIds: [],
    toolboxAuthAttendeeIds: [],
    toolboxAuth: false,
    callTeacherGroups: callTeacherGroupsInit(),
    breakoutRoomMemberStatus: {},
    broadcastMessage: '',
    getSessionInfoState: null,
    sessionInfo: null,
    videoFilter: null,
    groupInfo: null,
    classroomPerformance: null
  },
  initial: States.Idle,
  states: {
    [States.Idle]: {
      on: {
        [ActionTypes.GetSessionInfoSuccess]: {
          actions: assign({
            getSessionInfoState: 'success',
            sessionInfo: (ctx, evt) => evt.payload.sessionInfo
          }),
        },
        [ActionTypes.GetSessionInfoFailure]: {
          actions: assign({
            getSessionInfoState: 'failure'
          }),
        },
        [ActionTypes.GetGroupInfo]: {
          actions: assign({
            groupInfo: (ctx, evt) => evt.payload.groupInfo
          }),
        },
        [ActionTypes.Join]: {
          target: States.Joining,
          actions: assign({
            roomId: (ctx, evt) => evt.payload.roomId,
            userName: (ctx, evt) => evt.payload.userName,
            role: (ctx, evt) => evt.payload.role,
            userId: (ctx, evt) => evt.payload.userId
          })
        },
      }
    },
    [States.Joining]: {
      invoke: {
        id: 'joinMeeting',
        src: 'joinMeeting',
        onDone: {
          target: States.Joined,
          actions: assign({
            joinInfo: (ctx, evt) => evt.data,
            attendeeId: (ctx, evt) => evt.data?.attendee?.AttendeeId,
          })
        },
        onError: {
          target: States.Failure,
        }
      }
    },
    [States.Joined]: {
      initial: Roles.Student,
      entry: send(ActionTypes.SetRoleState),
      on: {
        [ActionTypes.SetRoleState]: [
          {
            cond: (ctx, evt) => ctx.role === Roles.Teacher,
            target: `.${Roles.Teacher}`,
          },
          {
            cond: (ctx, evt) => ctx.role === Roles.Advisor,
            target: `.${Roles.Advisor}`,
          },
          {
            cond: (ctx, evt) => ctx.role === Roles.Observer,
            target: `.${Roles.Observer}`,
          },
          { target: `.${Roles.Student}` }
        ],
        [ActionTypes.VideoBackgroundImageEffect]: {
          actions: [
            'videoBackgroundImageEffectHandler',
            assign({ videoFilter: 'Replacement' }),
          ]
        },
        [ActionTypes.VideoBlurBackgroundEffect]: {
          actions: [
            'videoBlurBackgroundEffectHandler',
            assign({ videoFilter: 'Blur' }),
          ]
        },
        [ActionTypes.ClearVideoBackgroundEffect]: {
          actions: [
            'clearVideoBackgroundEffectHandler',
            assign({ videoFilter: null }),
          ]
        },
      },
      states: {
        [Roles.Teacher]: {
          type: 'parallel',
          on: {
            [ActionTypes.AssignMultiUserRewards]: {
              actions: ['assignMultiUserRewards']
            },
            [ActionTypes.OnStage]: {
              actions: [
                'broadcastEvent',
                assign({
                  stagedAttendeeIds: (ctx, evt) => {
                    return [...(new Set([...ctx.stagedAttendeeIds, evt.payload.attendeeId]))];
                  },
                  raisedHandAttendeeIds: (ctx, evt) => {
                    const target = evt.payload.attendeeId;
                    return ctx.raisedHandAttendeeIds.filter(attendeeId => target !== attendeeId);
                  }
                }),
              ]
            },
            [ActionTypes.Unstage]: {
              actions: [
                'broadcastEvent',
                assign({
                  stagedAttendeeIds: (ctx, evt) => {
                    const target = evt.payload.attendeeId;
                    return ctx.stagedAttendeeIds.filter(attendeeId => target !== attendeeId);
                  }
                })
              ]
            },

            [ActionTypes.DisableChat]: {
              actions: [
                'broadcastEvent',
                assign({
                  chatDisabledAttendeeIds: (ctx, evt) => [...(new Set([...ctx.chatDisabledAttendeeIds, evt.payload.attendeeId]))],
                })
              ]
            },
            [ActionTypes.EnableChat]: {
              actions: [
                'broadcastEvent',
                  assign({
                  chatDisabledAttendeeIds: (ctx, evt) => {
                    const target = evt.payload.attendeeId;
                    return ctx.chatDisabledAttendeeIds.filter(attendeeId => target !== attendeeId);
                  }
                })
              ]
            },
            
            [ActionTypes.OnStageFromAdvisor]: {
              actions: assign({
                stagedAttendeeIds: (ctx, evt) => {
                  return [...(new Set([...ctx.stagedAttendeeIds, evt.payload.attendeeId]))];
                },
                raisedHandAttendeeIds: (ctx, evt) => {
                  const target = evt.payload.attendeeId;
                  return ctx.raisedHandAttendeeIds.filter(attendeeId => target !== attendeeId);
                }
              })
            },
            [ActionTypes.UnstageFromAdvisor]: {
              actions: [
                assign({
                  stagedAttendeeIds: (ctx, evt) => {
                    const target = evt.payload.attendeeId;
                    return ctx.stagedAttendeeIds.filter(attendeeId => target !== attendeeId);
                  }
                })
              ]
            },
            [ActionTypes.UpdateStaged]: {
              actions: [
                'broadcastEvent',
                assign({
                  stagedAttendeeIds: (ctx, evt) => evt.payload.stagedAttendeeIds
                }),
              ]
            },
            [ActionTypes.UpdateAppStaged]: {
              actions: 'broadcastEvent'
            },
            [ActionTypes.ClearStage]: {
              actions: assign({
                stagedAttendeeIds: []
              })
            },
            [ActionTypes.OnObject]: {
              actions: [
                'broadcastEvent',
                assign({
                  objectedAttendeeIds: (ctx, evt) => {
                    return [...(new Set([...ctx.objectedAttendeeIds, evt.payload.attendeeId]))];
                  }
                }),
              ]
            },
            [ActionTypes.UnObject]: {
              actions: [
                'broadcastEvent',
                assign({
                  objectedAttendeeIds: (ctx, evt) => {
                    const target = evt.payload.attendeeId;
                    return ctx.objectedAttendeeIds.filter(attendeeId => target !== attendeeId);
                  }
                })
              ]
            },
            [ActionTypes.LowerAllAttendeesHand]: {
              actions: [
                'broadcastEvent',
                assign({
                  raisedHandAttendeeIds: () => []
                })
              ]
            },
            [ActionTypes.LowerAllAttendeesHandFromAdvisor]: {
              actions: assign({
                raisedHandAttendeeIds: () => []
              })
            },
            [ActionTypes.RaiseHand]: {
              actions: assign({
                raisedHandAttendeeIds: (ctx, evt) => [...(new Set([...ctx.raisedHandAttendeeIds, evt.payload.attendeeId]))],
              })
            },
            [ActionTypes.LowerHand]: {
              actions: assign({
                raisedHandAttendeeIds: (ctx, evt) => {
                  const target = evt.payload.attendeeId;
                  return ctx.raisedHandAttendeeIds.filter(attendeeId => target !== attendeeId);
                }
              })
            },
            [ActionTypes.LowerHandFromTeacher]: {
              actions: [
                'broadcastEvent',
                assign({
                  raisedHandAttendeeIds: (ctx, evt) => {
                    const target = evt.payload.attendeeId;
                    return ctx.raisedHandAttendeeIds.filter(attendeeId => target !== attendeeId);
                  }
                })
              ]
            },
            [ActionTypes.LowerHandFromAdvisor]: {
              actions: [
                assign({
                  raisedHandAttendeeIds: (ctx, evt) => {
                    const target = evt.payload.attendeeId;
                    return ctx.raisedHandAttendeeIds.filter(attendeeId => target !== attendeeId);
                  }
                })
              ]
            },
            [ActionTypes.NoFocus]: {
              actions: 'noFocusHandler'
            },
            [ActionTypes.MuteAttendee]: {
              actions: 'broadcastEvent'
            },
            [ActionTypes.UnMuteAttendee]: {
              actions: 'broadcastEvent'
            },
            [ActionTypes.MuteAttendeeFromAdvisor]: {
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              actions: 'muteHandler'
            },
            [ActionTypes.UnMuteAttendeeFromAdvisor]: {
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              actions: 'unMuteHandler'
            },
            [ActionTypes.MuteAllAttendees]: {
              actions: 'broadcastEvent'
            },
            [ActionTypes.UnMuteAllAttendees]: {
              actions: 'broadcastEvent'
            },
            [ActionTypes.OpenVideoAttendee]: {
              actions: 'broadcastEvent'
            },
            [ActionTypes.CloseVideoAttendee]: {
              actions: 'broadcastEvent'
            },
            [ActionTypes.OpenVideoAttendeeFromAdvisor]: {
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              actions: 'openVideoHandler'
            },
            [ActionTypes.CloseVideoAttendeeFromAdvisor]: {
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              actions: 'closeVideoHandler'
            },
            [ActionTypes.OpenSharingScreen]: {
              actions: ['closeSharingScreenHandler', 'broadcastEvent']
            },
            [ActionTypes.CloseSharingScreen]: {
              actions: ['broadcastEvent', 'openSharingScreenHandler']
            },
            [ActionTypes.StartAllWhiteboard]: {
              target: `.${States.Whiteboard}.${States.Whiteboarding}`,
              actions: [
                'broadcastEvent',
              ],
            },
            [ActionTypes.StartWhiteboard]: {
              target: `.${States.Whiteboard}.${States.Whiteboarding}`,
              actions: [
                'broadcastEvent',
                assign({
                  whiteboardingAttendeeIds: (ctx, evt) => evt.payload.attendeeIds
                })
              ],
            },
            [ActionTypes.EndWhiteboard]: {
              target: `.${States.Whiteboard}.${States.Idle}`,
              actions: [
                'broadcastEvent',
                assign({
                  whiteboardingAttendeeIds: (ctx, evt) => []
                })
              ],
            },
            [ActionTypes.SendReward]: {
              target: `.${States.RewardAnimationState}.${States.Active}`,
              actions: ['broadcastEvent', 'sendReward']
            },
            [ActionTypes.PauseReward]: {
              target: `.${States.RewardAnimationState}.${States.Paused}`,
              actions: 'pauseReward'
            },
            [ActionTypes.UpdateReward]: {
              actions: ['broadcastEvent', 'updateReward', 'plusRewardUserEvent']
            },
            [ActionTypes.UpdateRewardFromAdvisor]: {
              actions: 'updateReward'
            },
            [ActionTypes.QuizClose]: {
              target: `.${States.Quiz}.${States.Idle}`,
              actions: [
                'broadcastEvent',
                assign({
                  quiz: (ctx, evt) => ({
                    optionCount: 0,
                    attendeeAnswers: {}
                  })
                })
              ]
            },
            [ActionTypes.VideoIsMirroring]: {
              actions: assign({
                videoIsMirroring: (ctx, evt) => evt.payload.videoIsMirroring
              })
            },
            [ActionTypes.SetWorldWall]: {
              actions: 'broadcastEvent'
            },
            [ActionTypes.EnableToolbox]: {
              actions: [
                'broadcastEvent',
                assign({
                  toolboxAuthAttendeeIds: (ctx, evt) => [...(new Set([...ctx.toolboxAuthAttendeeIds, evt.payload.attendeeId]))],
                })
              ]
            },
            [ActionTypes.DisableToolbox]: {
              actions: [
                'broadcastEvent',
                assign({
                  toolboxAuthAttendeeIds: (ctx, evt) => {
                    const target = evt.payload.attendeeId;
                    return ctx.toolboxAuthAttendeeIds.filter(attendeeId => target !== attendeeId);
                  }
                })
              ]
            },
            [ActionTypes.AdvisorEnableToolbox]: {
              actions: [
                assign({
                  toolboxAuthAttendeeIds: (ctx, evt) => [...(new Set([...ctx.toolboxAuthAttendeeIds, evt.payload.attendeeId]))],
                })
              ]
            },
            [ActionTypes.AdvisorDisableToolbox]: {
              actions: [
                assign({
                  toolboxAuthAttendeeIds: (ctx, evt) => {
                    const target = evt.payload.attendeeId;
                    return ctx.toolboxAuthAttendeeIds.filter(attendeeId => target !== attendeeId);
                  }
                })
              ]
            },
            [ActionTypes.AddToolboxAuthAttendeeIds]: {
              actions: assign({
                toolboxAuthAttendeeIds: (ctx, evt) => {
                  return [...(new Set([...ctx.toolboxAuthAttendeeIds, evt.payload.attendeeId]))]
                }
              })
            },
            [ActionTypes.RemoveToolboxAuthAttendeeIds]: {
              actions: assign({
                toolboxAuthAttendeeIds: (ctx, evt) => {
                  const toolboxAuthAttendeeIdsSet = new Set([...ctx.toolboxAuthAttendeeIds]);
                  toolboxAuthAttendeeIdsSet.delete(evt.payload.attendeeId);
                  return [...toolboxAuthAttendeeIdsSet]
                }
              })
            },
            [ActionTypes.BreakoutMeetingInvite]: {
              actions: 'broadcastEvent',
              target: `.${States.BreakoutMeeting}.${States.Grouping}`,
            },
            [ActionTypes.BreakoutMeetingLeave]: {
              actions: 'broadcastEvent',
            },
            [ActionTypes.CallTeacherGroups]: {
              actions: assign({
                callTeacherGroups: (ctx, evt) => {
                  const groupData = evt.payload.data;
                  const isExist = ctx.callTeacherGroups.filter(group => group.breakoutRoomId === groupData.breakoutRoomId).length !== 0;
                  let callTeacherGroups = [];

                  if (isExist) {
                    callTeacherGroups = [...(new Set([...ctx.callTeacherGroups]))]
                  } else {
                    callTeacherGroups = [...(new Set([...ctx.callTeacherGroups, groupData]))]
                  }
                  return callTeacherGroups;
                }
              })
            },
            [ActionTypes.RemoveCallTeacherGroups]: {
              actions: assign({
                callTeacherGroups: (ctx, evt) => {
                  const breakoutRoomId = evt.payload.breakoutRoomId;
                  return ctx.callTeacherGroups.filter(group => group.breakoutRoomId !== breakoutRoomId)
                }
              })
            },
            [ActionTypes.RemoveAllCallTeacherGroups]: {
              actions: assign({
                callTeacherGroups: []
              })
            },
            [ActionTypes.EnterBreakoutRoom]: {
              actions: assign({
                breakoutRoomMemberStatus: (ctx, evt) => {
                  return {
                    type: 'enterBreakoutRoom',
                    ...evt.payload
                  }
                }
              })
            },
            [ActionTypes.LeaveBreakoutRoom]: {
              actions: assign({
                breakoutRoomMemberStatus: (ctx, evt) => {
                  return {
                    type: 'leaveBreakoutRoom',
                    ...evt.payload
                  }
                }
              })
            },
            [ActionTypes.UpdateVideoList]: {
              actions: [
                'broadcastEvent',
                assign({
                  videoList: (ctx, evt) => evt.payload.videoList
                })
              ]
            },
            [ActionTypes.AddOrderRosters]: {
              actions: [
                assign({
                  orderRosters: (ctx, evt) => {
                    const orderRostersSet = new Set(ctx.orderRosters);
                    orderRostersSet.add(evt.payload.roster);
                    return [...orderRostersSet]
                  }
                })
              ]
            },
            [ActionTypes.RemoveOrderRosters]: {
              actions: [
                assign({
                  orderRosters: (ctx, evt) => ctx.orderRosters.filter(roster => evt.payload.chimeAttendeeId !== roster.chimeAttendeeId)
                })
              ]
            },
            [ActionTypes.UpdateOrderRosters]: {
              actions: [
                assign({
                  orderRosters: (ctx, evt) => evt.payload.orderRosters
                })
              ],
            },
            [ActionTypes.ActiveTile]: {
              target: `.${States.TileState}.${States.Active}`,
            },
            [ActionTypes.PausedTile]: {
              target: `.${States.TileState}.${States.Paused}`,
            },
            [ActionTypes.TeacherStartRecord]: {
              actions: 'broadcastEvent',
              target: `.${States.RecordVideo}.${RECORD_VIDEO_STATE.On}`,
            },
            [ActionTypes.TeacherStopRecord]: {
              actions: 'broadcastEvent',
              target: `.${States.RecordVideo}.${RECORD_VIDEO_STATE.Off}`,
            },
            [ActionTypes.AdvisorStartRecord]: {
              target: `.${States.RecordVideo}.${RECORD_VIDEO_STATE.On}`,
            },
            [ActionTypes.AdvisorStopRecord]: {
              target: `.${States.RecordVideo}.${RECORD_VIDEO_STATE.Off}`,
            },
            [ActionTypes.ObserverStopRecord]: {
              target: `.${States.RecordVideo}.${RECORD_VIDEO_STATE.Off}`,
            },
            [ActionTypes.OpenClassroomPerformance]: {
              actions: 'broadcastEvent'
            },
            [ActionTypes.CloseClassroomPerformance]: {
              actions: 'broadcastEvent'
            },
          },
          states: {
            [States.Whiteboard]: {
              initial: States.Idle,
              states: {
                [States.Idle]: {},
                [States.Whiteboarding]: {},
              }
            },
            [States.Quiz]: {
              initial: States.Idle,
              states: {
                [States.Idle]: {
                  on: {
                    [ActionTypes.QuizSet]: States.Setting
                  }
                },
                [States.Setting]: {
                  on: {
                    [ActionTypes.QuizStart]: {
                      target: States.Starting,
                      actions: [
                        'broadcastEvent',
                        assign({
                          quiz: (ctx, evt) => ({
                            ...ctx.quiz,
                            optionCount: evt.payload.optionCount
                          })
                        })
                      ]
                    }
                  }
                },
                [States.Starting]: {
                  on: {
                    [ActionTypes.QuizAnswer]: {
                      actions: assign({
                        quiz: (ctx, evt) => {
                          const { attendeeId, answer } = evt.payload;
                          return {
                            ...ctx.quiz,
                            attendeeAnswers: {
                              ...ctx.quiz.attendeeAnswers,
                              [attendeeId]: answer
                            }
                          }
                        }
                      }),
                    },
                    [ActionTypes.QuizEnd]: {
                      target: States.End,
                      actions: 'broadcastEvent'
                    }
                  }
                },
                [States.End]: {
                  on: {
                    [ActionTypes.QuizReview]: States.Reviewing
                  }
                },
                [States.Reviewing]: {},
              }
            },
            [States.BreakoutMeeting]: {
              initial: States.Idle,
              states: {
                [States.Idle]: {},
                [States.Grouping]: {},
              }
            },
            [States.TileState]: {
              initial: States.Idle,
              states: {
                [States.Idle]: {},
                [States.Active]: {},
                [States.Paused]: {},
              }
            },
            [States.RewardAnimationState]: {
              initial: States.Paused,
              states: {
                [States.Paused]: {},
                [States.Active]: {}
              }
            },
            [States.RecordVideo]: {
              initial: RECORD_VIDEO_STATE.Idle,
              states: {
                // 閒置
                [RECORD_VIDEO_STATE.Idle]: {
                  on: {
                    [RECORD_VIDEO_EVENT.turnOn]: {
                      target: RECORD_VIDEO_STATE.On,
                    },
                    [RECORD_VIDEO_EVENT.turnOff]: {
                      target: RECORD_VIDEO_STATE.Off,
                    },
                  },
                },
                // 錄影中
                [RECORD_VIDEO_STATE.On]: {
                  on: {
                    [RECORD_VIDEO_EVENT.turnOff]: {
                      target: RECORD_VIDEO_STATE.Off,
                    },
                  },
                },
                // 結束錄影
                [RECORD_VIDEO_STATE.Off]: {
                  on: {
                    [RECORD_VIDEO_EVENT.turnOn]: {
                      target: RECORD_VIDEO_STATE.On,
                    },
                  },
                },
              },
            }
          }
        },
        [Roles.Student]: {
          type: 'parallel',
          on: {
            [ActionTypes.AssignMultiUserRewards]: {
              actions: 'assignMultiUserRewards'
            },
            [ActionTypes.OnStage]: [{
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              target: `.${States.RaiseHand}.${States.Idle}`,
              actions: assign({
                stagedAttendeeIds: (ctx, evt) => {
                  return [...(new Set([...ctx.stagedAttendeeIds, evt.payload.attendeeId]))];
                }
              })
            }, {
              actions: assign({
                stagedAttendeeIds: (ctx, evt) => {
                  return [...(new Set([...ctx.stagedAttendeeIds, evt.payload.attendeeId]))];
                }
              })
            }
            ],
            [ActionTypes.Unstage]: {
              actions: [
                assign({
                  stagedAttendeeIds: (ctx, evt) => {
                    const target = evt.payload.attendeeId;
                    return ctx.stagedAttendeeIds.filter(attendeeId => target !== attendeeId);
                  }
                })
              ]
            },
            [ActionTypes.DisableChat]: {
              actions: [
                assign({
                  chatDisabledAttendeeIds: (ctx, evt) => [...(new Set([...ctx.chatDisabledAttendeeIds, evt.payload.attendeeId]))],
                })
              ]
            },
            [ActionTypes.EnableChat]: {
              actions: [
                assign({
                  chatDisabledAttendeeIds: (ctx, evt) => {
                    const target = evt.payload.attendeeId;
                    return ctx.chatDisabledAttendeeIds.filter(attendeeId => target !== attendeeId);
                  }
                })
              ]
            },
            [ActionTypes.OnStageFromAdvisor]: {
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              target: `.${States.RaiseHand}.${States.Idle}`,
              actions: assign({
                stagedAttendeeIds: (ctx, evt) => {
                  return [...(new Set([...ctx.stagedAttendeeIds, evt.payload.attendeeId]))];
                }
              })
            },
            [ActionTypes.UnstageFromAdvisor]: {
              actions: [
                assign({
                  stagedAttendeeIds: (ctx, evt) => {
                    const target = evt.payload.attendeeId;
                    return ctx.stagedAttendeeIds.filter(attendeeId => target !== attendeeId);
                  }
                })
              ]
            },
            [ActionTypes.UpdateStaged]: [{
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              actions: assign({
                stagedAttendeeIds: (ctx, evt) => evt.payload.stagedAttendeeIds
              })
            }],
            [ActionTypes.OnObject]: {
              actions: assign({
                objectedAttendeeIds: (ctx, evt) => {
                  return [...(new Set([...ctx.objectedAttendeeIds, evt.payload.attendeeId]))];
                }
              })
            },
            [ActionTypes.UnObject]: {
              actions: assign({
                objectedAttendeeIds: (ctx, evt) => {
                  const target = evt.payload.attendeeId;
                  return ctx.objectedAttendeeIds.filter(attendeeId => target !== attendeeId);
                }
              })
            },
            [ActionTypes.RaiseHand]: {
              target: `.${States.RaiseHand}.${States.RaisingHand}`,
              actions: 'broadcastEvent'
            },
            [ActionTypes.LowerHand]: {
              target: `.${States.RaiseHand}.${States.Idle}`,
              actions: 'broadcastEvent'
            },
            [ActionTypes.LowerHandFromTeacher]: {
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              target: `.${States.RaiseHand}.${States.Idle}`
            },
            [ActionTypes.LowerHandFromAdvisor]: {
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              target: `.${States.RaiseHand}.${States.Idle}`
            },
            [ActionTypes.LowerAllAttendeesHand]: {
              target: `.${States.RaiseHand}.${States.Idle}`,
            },
            [ActionTypes.LowerAllAttendeesHandFromAdvisor]: {
              target: `.${States.RaiseHand}.${States.Idle}`,
            },
            [ActionTypes.MuteAttendee]: {
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              actions: 'muteHandler'
            },
            [ActionTypes.UnMuteAttendee]: {
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              actions: 'unMuteHandler'
            },
            [ActionTypes.MuteAttendeeFromAdvisor]: {
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              actions: 'muteHandler'
            },
            [ActionTypes.UnMuteAttendeeFromAdvisor]: {
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              actions: 'unMuteHandler'
            },
            [ActionTypes.MuteAllAttendees]: {
              actions: 'muteHandler'
            },
            [ActionTypes.UnMuteAllAttendees]: {
              actions: 'unMuteHandler'
            },
            [ActionTypes.OpenVideoAttendee]: {
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              actions: 'openVideoHandler'
            },
            [ActionTypes.CloseVideoAttendee]: {
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              actions: 'closeVideoHandler'
            },
            [ActionTypes.OpenVideoAttendeeFromAdvisor]: {
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              actions: 'openVideoHandler'
            },
            [ActionTypes.CloseVideoAttendeeFromAdvisor]: {
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              actions: 'closeVideoHandler'
            },
            [ActionTypes.OpenSharingScreen]: {
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              actions: 'openSharingScreenHandler'
            },
            [ActionTypes.CloseSharingScreen]: {
              actions: 'closeSharingScreenHandler'
            },
            [ActionTypes.NoFocus]: {
              actions: [
                'plusInattentiveUserEvent',
                'broadcastEvent',
              ]
            },
            [ActionTypes.SendReward]: {
              target: `.${States.RewardAnimationState}.${States.Active}`,
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              actions: 'sendReward'
            },
            [ActionTypes.PauseReward]: {
              target: `.${States.RewardAnimationState}.${States.Paused}`,
              actions: 'pauseReward'
            },
            [ActionTypes.UpdateReward]: {
              actions: 'updateReward'
            },
            [ActionTypes.SendRewardFromAdvisor]: {
              target: `.${States.RewardAnimationState}.${States.Active}`,
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              actions: 'sendReward'
            },
            [ActionTypes.PauseRewardFromAdvisor]: {
              target: `.${States.RewardAnimationState}.${States.Paused}`,
              actions: 'pauseReward'
            },
            [ActionTypes.UpdateRewardFromAdvisor]: {
              actions: 'updateReward'
            },
            [ActionTypes.StartWhiteboard]: [
              {
                cond: (ctx, evt) => evt.payload.attendeeIds.includes(ctx.attendeeId),
                target: `.${States.Whiteboard}.${States.Whiteboarding}`,
                actions: assign({
                  whiteboardingAttendeeIds: (ctx, evt) => evt.payload.attendeeIds
                })
              },
              {
                target: `.${States.Whiteboard}.${States.Idle}`
              }
            ],
            [ActionTypes.StartAllWhiteboard]: [
              {
                target: `.${States.Whiteboard}.${States.Whiteboarding}`,
              }
            ],
            [ActionTypes.EndWhiteboard]: {
              target: `.${States.Whiteboard}.${States.Idle}`,
            },
            [ActionTypes.QuizClose]: {
              target: `.${States.Quiz}.${States.Idle}`,
            },
            [ActionTypes.SetWorldWall]: {
              actions: assign({
                worldWall: (ctx, evt) => ({
                  switch: evt.payload.switch,
                  url: evt.payload.url
                })
              })
            },
            [ActionTypes.EnableToolbox]: {
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              actions: [
                'enableWhiteboardWritableHandler',
                assign({
                  toolboxAuth: (ctx, evt) => true
                })
              ]
            },
            [ActionTypes.DisableToolbox]: {
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              actions: [
                'disableWhiteboardWritableHandler',
                assign({
                  toolboxAuth: (ctx, evt) => false
                })
              ]
            },
            [ActionTypes.UpdateToolbox]: {
              actions: 'broadcastEvent'
            },
            [ActionTypes.AddToolboxAuthAttendeeIds]: {
              actions: 'broadcastEvent'
            },
            // [ActionTypes.RemoveToolboxAuthAttendeeIds]: {
            //   actions: 'broadcastEvent'
            // },
            [ActionTypes.AdvisorEnableToolbox]: {
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              actions: [
                'enableWhiteboardWritableHandler',
                assign({
                  toolboxAuth: (ctx, evt) => true
                })
              ]

            },
            [ActionTypes.AdvisorDisableToolbox]: {
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              actions: [
                'disableWhiteboardWritableHandler',
                assign({
                  toolboxAuth: (ctx, evt) => false
                })
              ]
            },
            [ActionTypes.BreakoutMeetingInvite]: {
              target: `.${States.BreakoutMeetingInvite}.${States.Inviting}`
            },
            [ActionTypes.BreakoutMeetingLeave]: {
              target: `.${States.BreakoutMeetingLeave}.${States.Leaving}`
            },
            [ActionTypes.BreakoutMeetingEnd]: {
              target: `.${States.BreakoutMeetingLeave}.${States.End}`
            },
            [ActionTypes.BroadcastMessageToAll]: {
              actions: assign({
                broadcastMessage: (ctx, evt) => evt.payload.message
              }),
              target: `.${States.BroadcastMessage}.${States.Open}`
            },
            [ActionTypes.CloseBroadcastModal]: {
              actions: assign({
                broadcastMessage: (ctx, evt) => ''
              }),
              target: `.${States.BroadcastMessage}.${States.Idle}`
            },
            [ActionTypes.ActiveTile]: {
              target: `.${States.TileState}.${States.Active}`,
            },
            [ActionTypes.PausedTile]: {
              target: `.${States.TileState}.${States.Paused}`,
            },
            [ActionTypes.OpenClassroomPerformance]: {
              target: `.${States.ClassroomPerformance}.${States.Open}`,
              actions: assign({
                classroomPerformance: (ctx, evt) => evt.payload.classroomPerformance
              }),
            },
            [ActionTypes.CloseClassroomPerformance]: {
              target: `.${States.ClassroomPerformance}.${States.Idle}`,
              actions: assign({
                classroomPerformance: (ctx, evt) => null
              }),
            },
            [ActionTypes.VideoIsMirroring]: {
              actions: assign({
                videoIsMirroring: (ctx, evt) => evt.payload.videoIsMirroring
              })
            },
          },
          states: {
            [States.Whiteboard]: {
              initial: States.Idle,
              states: {
                [States.Idle]: {},
                [States.Whiteboarding]: {},
              }
            },
            [States.RaiseHand]: {
              initial: States.Idle,
              states: {
                [States.Idle]: {},
                [States.RaisingHand]: {
                  entry: 'plusRaiseHandUserEvent'
                },
              }
            },
            [States.BreakoutMeetingInvite]: {
              initial: States.Idle,
              states: {
                [States.Idle]: {},
                [States.Inviting]: {},
              }
            },
            [States.BreakoutMeetingLeave]: {
              initial: States.Idle,
              states: {
                [States.Idle]: {},
                [States.Leaving]: {},
                [States.End]: {},
              }
            },
            [States.Quiz]: {
              initial: States.Idle,
              states: {
                [States.Idle]: {
                  on: {
                    [ActionTypes.QuizStart]: {
                      target: States.Starting,
                      actions: assign({
                        quiz: (ctx, evt) => ({
                          optionCount: evt.payload.optionCount
                        })
                      })
                    }
                  }
                },
                [States.Starting]: {
                  on: {
                    [ActionTypes.QuizAnswer]: {
                      actions: 'broadcastEvent',
                      target: States.Idle
                    },
                    [ActionTypes.QuizEnd]: States.Idle
                  }
                },
              }
            },
            [States.BroadcastMessage]: {
              initial: States.Idle,
              states: {
                [States.Idle]: {},
                [States.Open]: {},
              }
            },
            [States.TileState]: {
              initial: States.Idle,
              states: {
                [States.Idle]: {},
                [States.Active]: {},
                [States.Paused]: {},
              }
            },
            [States.RewardAnimationState]: {
              initial: States.Paused,
              states: {
                [States.Paused]: {},
                [States.Active]: {}
              }
            },
            [States.ClassroomPerformance]: {
              initial: States.Idle,
              states: {
                [States.Idle]: {},
                [States.Open]: {},
              }
            }
          }
        },
        [Roles.Observer]: {
          type: 'parallel',
          on: {
            [ActionTypes.OnStage]: {
              actions: [assign({
                stagedAttendeeIds: (ctx, evt) => {
                  return [...(new Set([...ctx.stagedAttendeeIds, evt.payload.attendeeId]))];
                }
              })]
            },
            [ActionTypes.Unstage]: {
              actions: [
                assign({
                  stagedAttendeeIds: (ctx, evt) => {
                    const target = evt.payload.attendeeId;
                    return ctx.stagedAttendeeIds.filter(attendeeId => target !== attendeeId);
                  }
                })
              ]
            },
            [ActionTypes.UpdateStaged]: [{
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              actions: assign({
                stagedAttendeeIds: (ctx, evt) => evt.payload.stagedAttendeeIds
              })
            }],
            [ActionTypes.UpdateReward]: {
              actions: 'updateReward'
            },
            [ActionTypes.UpdateRewardFromAdvisor]: {
              actions: 'updateReward'
            },
            [ActionTypes.AssignMultiUserRewards]: {
              actions: 'assignMultiUserRewards'
            },
            [ActionTypes.BreakoutMeetingLeave]: {
              target: `.${States.BreakoutMeetingLeave}.${States.Leaving}`
            },
            [ActionTypes.BreakoutMeetingEnd]: {
              target: `.${States.BreakoutMeetingLeave}.${States.End}`
            },
            [ActionTypes.EnableToolbox]: {
              actions: [
                assign({
                  toolboxAuthAttendeeIds: (ctx, evt) => [...(new Set([...ctx.toolboxAuthAttendeeIds, evt.payload.attendeeId]))],
                })
              ]
            },
            [ActionTypes.AddOrderRosters]: {
              actions: [
                assign({
                  orderRosters: (ctx, evt) => {
                    const orderRostersSet = new Set(ctx.orderRosters);
                    orderRostersSet.add(evt.payload.roster);
                    return [...orderRostersSet]
                  }
                })
              ]
            },
            [ActionTypes.RemoveOrderRosters]: {
              actions: [
                assign({
                  orderRosters: (ctx, evt) => ctx.orderRosters.filter(roster => evt.payload.chimeAttendeeId !== roster.chimeAttendeeId)
                })
              ]
            },
            [ActionTypes.UpdateOrderRosters]: {
              actions: [
                assign({
                  orderRosters: (ctx, evt) => evt.payload.orderRosters
                })
              ],
            },
            [ActionTypes.DisableToolbox]: {
              actions: [
                assign({
                  toolboxAuthAttendeeIds: (ctx, evt) => {
                    const target = evt.payload.attendeeId;
                    return ctx.toolboxAuthAttendeeIds.filter(attendeeId => target !== attendeeId);
                  }
                })
              ]
            },
          },
          states: {
            [States.BreakoutMeetingLeave]: {
              initial: States.Idle,
              states: {
                [States.Idle]: {},
                [States.Leaving]: {},
                [States.End]: {},
              }
            },
          }
        },
        [Roles.Advisor]: {
          type: 'parallel',
          on: {
            [ActionTypes.OnStage]: {
              actions: [
                assign({
                  stagedAttendeeIds: (ctx, evt) => {
                    return [...(new Set([...ctx.stagedAttendeeIds, evt.payload.attendeeId]))];
                  },
                  raisedHandAttendeeIds: (ctx, evt) => {
                    const target = evt.payload.attendeeId;
                    return ctx.raisedHandAttendeeIds.filter(attendeeId => target !== attendeeId);
                  }
                }),
              ]
            },
            [ActionTypes.Unstage]: {
              actions: [
                assign({
                  stagedAttendeeIds: (ctx, evt) => {
                    const target = evt.payload.attendeeId;
                    return ctx.stagedAttendeeIds.filter(attendeeId => target !== attendeeId);
                  }
                })
              ]
            },
            [ActionTypes.UpdateStaged]: [{
              cond: (ctx, evt) => evt.payload.attendeeId === ctx.attendeeId,
              actions: assign({
                stagedAttendeeIds: (ctx, evt) => evt.payload.stagedAttendeeIds
              })
            }],
            [ActionTypes.OnStageFromAdvisor]: {
              actions: [
                'broadcastEvent',
                assign({
                  stagedAttendeeIds: (ctx, evt) => {
                    return [...(new Set([...ctx.stagedAttendeeIds, evt.payload.attendeeId]))];
                  },
                  raisedHandAttendeeIds: (ctx, evt) => {
                    const target = evt.payload.attendeeId;
                    return ctx.raisedHandAttendeeIds.filter(attendeeId => target !== attendeeId);
                  }
                }),
              ]
            },
            [ActionTypes.UnstageFromAdvisor]: {
              actions: [
                'broadcastEvent',
                assign({
                  stagedAttendeeIds: (ctx, evt) => {
                    const target = evt.payload.attendeeId;
                    return ctx.stagedAttendeeIds.filter(attendeeId => target !== attendeeId);
                  }
                })
              ]
            },
            [ActionTypes.ClearStage]: {
              actions: assign({
                stagedAttendeeIds: []
              })
            },
            [ActionTypes.UpdateVideoList]: {
              actions: assign({
                videoList: (ctx, evt) => {
                  return evt.payload.videoList
                }
              })
            },
            [ActionTypes.AddOrderRosters]: {
              actions: assign({
                orderRosters: (ctx, evt) => {
                  return [...(new Set([...ctx.orderRosters, evt.payload.roster]))];
                }
              }),
            },
            [ActionTypes.RemoveOrderRosters]: {
              actions: assign({
                orderRosters: (ctx, evt) => ctx.orderRosters.filter(roster => evt.payload.chimeAttendeeId !== roster.chimeAttendeeId)
              })
            },
            [ActionTypes.UpdateOrderRosters]: {
              actions: assign({
                orderRosters: (ctx, evt) => evt.payload.orderRosters
              }),
            },
            [ActionTypes.OnObject]: {
              actions: assign({
                objectedAttendeeIds: (ctx, evt) => {
                  return [...(new Set([...ctx.objectedAttendeeIds, evt.payload.attendeeId]))];
                }
              })
            },
            [ActionTypes.UnObject]: {
              actions: assign({
                objectedAttendeeIds: (ctx, evt) => {
                  const target = evt.payload.attendeeId;
                  return ctx.objectedAttendeeIds.filter(attendeeId => target !== attendeeId);
                }
              })
            },
            [ActionTypes.LowerAllAttendeesHand]: {
              actions: assign({
                raisedHandAttendeeIds: () => []
              })
            },
            [ActionTypes.LowerAllAttendeesHandFromAdvisor]: {
              actions: [
                'broadcastEvent',
                assign({
                  raisedHandAttendeeIds: () => []
                })
              ]
            },
            [ActionTypes.RaiseHand]: {
              actions: assign({
                raisedHandAttendeeIds: (ctx, evt) => [...(new Set([...ctx.raisedHandAttendeeIds, evt.payload.attendeeId]))],
              })
            },
            [ActionTypes.LowerHand]: {
              actions: assign({
                raisedHandAttendeeIds: (ctx, evt) => {
                  const target = evt.payload.attendeeId;
                  return ctx.raisedHandAttendeeIds.filter(attendeeId => target !== attendeeId);
                }
              })
            },
            [ActionTypes.LowerHandFromAdvisor]: {
              actions: [
                'broadcastEvent',
                assign({
                  raisedHandAttendeeIds: (ctx, evt) => {
                    const target = evt.payload.attendeeId;
                    return ctx.raisedHandAttendeeIds.filter(attendeeId => target !== attendeeId);
                  }
                })
              ]
            },
            [ActionTypes.LowerHandFromTeacher]: {
              actions: [
                assign({
                  raisedHandAttendeeIds: (ctx, evt) => {
                    const target = evt.payload.attendeeId;
                    return ctx.raisedHandAttendeeIds.filter(attendeeId => target !== attendeeId);
                  }
                })
              ]
            },
            [ActionTypes.OpenVideoAttendeeFromAdvisor]: {
              actions: 'broadcastEvent'
            },
            [ActionTypes.CloseVideoAttendeeFromAdvisor]: {
              actions: 'broadcastEvent'
            },
            [ActionTypes.MuteAttendeeFromAdvisor]: {
              actions: 'broadcastEvent'
            },
            [ActionTypes.UnMuteAttendeeFromAdvisor]: {
              actions: 'broadcastEvent'
            },
            [ActionTypes.UpdateReward]: {
              actions: 'updateReward'
            },
            [ActionTypes.SendRewardFromAdvisor]: {
              target: `.${States.RewardAnimationState}.${States.Active}`,
              actions: ['broadcastEvent', 'sendReward']
            },
            [ActionTypes.PauseRewardFromAdvisor]: {
              target: `.${States.RewardAnimationState}.${States.Paused}`,
              actions: 'pauseReward'
            },
            [ActionTypes.UpdateRewardFromAdvisor]: {
              actions: ['broadcastEvent', 'updateReward', 'plusRewardUserEvent']
            },
            [ActionTypes.AssignMultiUserRewards]: {
              actions: 'assignMultiUserRewards'
            },
            [ActionTypes.TeacherStartRecord]: {
              target: `.${States.RecordVideo}.${RECORD_VIDEO_STATE.On}`,
            },
            [ActionTypes.TeacherStopRecord]: {
              target: `.${States.RecordVideo}.${RECORD_VIDEO_STATE.Off}`,
            },
            [ActionTypes.AdvisorStartRecord]: {
              actions: 'broadcastEvent',
              target: `.${States.RecordVideo}.${RECORD_VIDEO_STATE.On}`,
            },
            [ActionTypes.AdvisorStopRecord]: {
              actions: 'broadcastEvent',
              target: `.${States.RecordVideo}.${RECORD_VIDEO_STATE.Off}`,
            },
            [ActionTypes.ObserverStopRecord]: {
              target: `.${States.RecordVideo}.${RECORD_VIDEO_STATE.Off}`,
            },
            [ActionTypes.EnableToolbox]: {
              actions: [
                assign({
                  toolboxAuthAttendeeIds: (ctx, evt) => [...(new Set([...ctx.toolboxAuthAttendeeIds, evt.payload.attendeeId]))],
                })
              ]
            },
            [ActionTypes.DisableToolbox]: {
              actions: [
                assign({
                  toolboxAuthAttendeeIds: (ctx, evt) => {
                    const target = evt.payload.attendeeId;
                    return ctx.toolboxAuthAttendeeIds.filter(attendeeId => target !== attendeeId);
                  }
                })
              ]
            },
            [ActionTypes.AddToolboxAuthAttendeeIds]: {
              actions: assign({
                toolboxAuthAttendeeIds: (ctx, evt) => {
                  return [...(new Set([...ctx.toolboxAuthAttendeeIds, evt.payload.attendeeId]))]
                }
              })
            },
            [ActionTypes.RemoveToolboxAuthAttendeeIds]: {
              actions: assign({
                toolboxAuthAttendeeIds: (ctx, evt) => {
                  return ctx.toolboxAuthAttendeeIds.filter(attendeeId => attendeeId !== evt.payload.attendeeId);
                }
              })
            },
            [ActionTypes.AdvisorEnableToolbox]: {
              actions: [
                'broadcastEvent',
                assign({
                  toolboxAuthAttendeeIds: (ctx, evt) => [...(new Set([...ctx.toolboxAuthAttendeeIds, evt.payload.attendeeId]))],
                })
              ]
            },
            [ActionTypes.AdvisorDisableToolbox]: {
              actions: [
                'broadcastEvent',
                assign({
                  toolboxAuthAttendeeIds: (ctx, evt) => {
                    const target = evt.payload.attendeeId;
                    return ctx.toolboxAuthAttendeeIds.filter(attendeeId => target !== attendeeId);
                  }
                })
              ]
            },
            [ActionTypes.VideoIsMirroring]: {
              actions: assign({
                videoIsMirroring: (ctx, evt) => evt.payload.videoIsMirroring
              })
            },
          },
          states: {
            [States.Whiteboard]: {
              initial: States.Idle,
              states: {
                [States.Idle]: {},
                [States.Whiteboarding]: {},
              }
            },
            [States.BreakoutMeeting]: {
              initial: States.Idle,
              states: {
                [States.Idle]: {},
                [States.Grouping]: {},
              }
            },
            [States.RewardAnimationState]: {
              initial: States.Paused,
              states: {
                [States.Paused]: {},
                [States.Active]: {}
              }
            },
            [States.RecordVideo]: {
              initial: RECORD_VIDEO_STATE.Idle,
              states: {
                // 閒置
                [RECORD_VIDEO_STATE.Idle]: {
                  on: {
                    [RECORD_VIDEO_EVENT.turnOn]: {
                      target: RECORD_VIDEO_STATE.On,
                    },
                    [RECORD_VIDEO_EVENT.turnOff]: {
                      target: RECORD_VIDEO_STATE.Off,
                    },
                  },
                },
                // 錄影中
                [RECORD_VIDEO_STATE.On]: {
                  on: {
                    [RECORD_VIDEO_EVENT.turnOff]: {
                      target: RECORD_VIDEO_STATE.Off,
                    },
                  },
                },
                // 結束錄影
                [RECORD_VIDEO_STATE.Off]: {
                  on: {
                    [RECORD_VIDEO_EVENT.turnOn]: {
                      target: RECORD_VIDEO_STATE.On,
                    },
                  },
                },
              },
            }
          }
        }
      },
    },
    [States.Failure]: {}
  }
}, {
  actions: {
    assignMultiUserRewards: assign({
      rewards: (ctx, evt) => {
        const { rewards } = ctx;
        const { rewardsMap } = evt.payload;
        return {
          ...rewards,
          ...rewardsMap,
        }
      }
    }),
    sendReward: assign({
      rewards: (ctx, evt) => {
        const { rewards } = ctx;
        const { userId } = evt.payload;
        const nextAnimate = new Set([...rewards.animate]);
        nextAnimate.add(userId);
        return {
          ...rewards,
          animate: [...nextAnimate]
        }
      }
    }),
    pauseReward: assign({
      rewards: (ctx, evt) => {
        const { rewards } = ctx;
        const { userId } = evt.payload;
        const nextAnimate = new Set([...rewards.animate]);
        nextAnimate.delete(userId);
        return {
          ...rewards,
          animate: [...nextAnimate]
        }
      }
    }),
    updateReward: assign({
      rewards: (ctx, evt) => {
        const { rewards } = ctx;
        const { userId } = evt.payload;
        const currentTargetValue = rewards[userId] || 0
        return {
          ...rewards,
          [userId]: currentTargetValue + 1
        }
      }
    })
  }
});
