import React from 'react';
import { useRosterState, useRemoteVideoTileState } from 'amazon-chime-sdk-component-library-react';
import { useToolboxAuth } from 'utils/hooks/useToolboxAuth';
import { ClassType } from 'constants/index';
import RemoteVideo from './RemoteVideo';
import TeacherVideo from './TeacherVideo';
import ChatRoomButton from 'components/NewOneBoard/ChatRoom/ChatRoomButton';
import RaiseHandButton from 'components/NewOneBoard/RaiseHand/RaiseHandButton';
import { useMeetingState } from '@oneboard/meeting';
import AllMicroButton from 'components/NewOneBoard/AllMicroButton/AllMicroButton';
import VideoPanelContainer from 'components/NewOneBoard/VideoInfo/common/VideoPanelContainer';
import ButtonContainer from 'components/NewOneBoard/VideoInfo/common/ButtonContainer';

export function TeacherVideoPanel({ isSettingModalOpen, muteDefault, setMuteDefault }) {
  const muteDefaultHandler = (bool) => setMuteDefault(bool);
  const { roster } = useRosterState();
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const { queryToolboxAuthById, toggleToolboxAuth } = useToolboxAuth();
  const meetingState = useMeetingState();
  const { courseType } = meetingState.context;
  const teacher = Object.values(roster).find((attendee) => attendee.role === 'teacher');
  const student = Object.values(roster).find((attendee) => attendee.role === 'student');

  const studentAttendeeId = student?.chimeAttendeeId;

  if (!teacher) {
    return null;
  }

  return (
    <VideoPanelContainer>
      <div>
        {teacher && (
          <TeacherVideo userType={teacher.role} teacherName={teacher.name} isSettingModalOpen={isSettingModalOpen} />
        )}
        {courseType === ClassType.SyncSingle && studentAttendeeId && (
          <RemoteVideo
            userType={teacher.role}
            tileId={attendeeIdToTileId[studentAttendeeId]}
            attendeeId={studentAttendeeId}
            studentName={student.name}
            toggleToolboxAuth={toggleToolboxAuth}
            queryToolboxAuthById={queryToolboxAuthById}
          />
        )}
      </div>
      <ButtonContainer>
        {courseType !== ClassType.SyncSingle && (
          <>
            <AllMicroButton muteDefault={muteDefault} muteDefaultControl={muteDefaultHandler} />
            <RaiseHandButton />
          </>
        )}
        <ChatRoomButton />
      </ButtonContainer>
    </VideoPanelContainer>
  );
}
